import React from 'react';
import { Box, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { VideoFrame } from '../../components/VideoFrame';
import { PageTitle, PageTitle2 } from '../../components/PageTitle';
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import IntegrationsGrid from '../images/integrationsGrid.svg';
import TeamDashLaptop from '../images/teamDashOnLaptop.svg';
import ItMonitoring from '../images/itMonitoring.svg';

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Sales Ops</title>
    </Helmet>

    <WrapperBackground color={colors.trulyDark} background="serverRoom">
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Create an Unfair Data Advantage
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Outmaneuver the competition through better information sharing
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground color={colors.trulyDark} background="managedByQ" gradientType="light">
      <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1 / 2]}>
          <Large as="p" color={colors.trulyDark} my="1.5rem">
            "With one of our past providers, onboarding a new rep here probably took 50 times as long as it does now
            with Truly. It has saved us so much time just using Truly"
          </Large>
          <VideoFrame background="evan" videoLink="https://player.vimeo.com/video/268687998?autoplay=1" />
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid color="#292929">
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1 / 2, 1 / 2]}>
          <CenteredImg src={TeamDashLaptop} alt="Team Dashboard" />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
            ZERO-TOUCH CRM SYNC
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
            All sales communication platforms require at least some manual action to get data syncing to CRM, making
            your data look like swiss cheese.
            <br />
            <br />
            We offer the only CRM integration that gets 100% of your call/text/email data into CRM
            <br />
            <br />
            This ensures every system of record (CRM, helpdesk, data warehouse) has a true representation of your
            customer engagement.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']}>
        <Box width={[1, 2 / 3]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
            BETTER ATTRIBUTION, BETTER REPORTING
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            55% of your reps' activity data doesn't make it into the CRM. Only 27% is correctly attributed to the right
            contacts, accounts and opportunities.
            <br />
            <br />
            Truly's intelligent algorithms act as a 'virtual assistant', connecting dozens of data points across
            communication history, calendar and CRM to ensure activity is attributed to the right things.
            <br />
            <br />
            This increases your data accuracy by as much as 2x
          </Large>
        </Box>
        <Box width={[2 / 3, 1 / 2]} pl={['0rem', '3rem']}>
          <WidthImg src={IntegrationsGrid} alt="Integration"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color="#292929">
      <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
        <Box width={[1 / 2, 2 / 3]} pb="2rem">
          <CenteredImg src={ItMonitoring} alt="IT Monitoring" />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Title3 as="h2" color={colors.accentSage} bold mb="1.5rem" center>
            EASY TO DEPLOY AND ADOPT
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem" center>
            Sales tools almost always require complex implementation cycles and onerous infosec reviews
            <br />
            <br />
            Truly was architected to enable turnkey deployments in bank-grade Salesforce environments. We require no
            package installs, work entirely via Oauth, are resilient to breaking changes/SFDC platform issues and store
            almost no PII data. Reps onboard themselves with just a few clicks.
            <br />
            <br />
            As a result, we can run full deployments in under a day, and handle all rep onboarding/training/engagement
            on your behalf.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>
  </Default>
);

export default SolutionsPage;
